<template>
  <div style="padding:10px 5px 5px 5px;">
    <draggable
          v-if="landing.menu.style !== 'swipe'"
          tag="ul"
          class="canvas-menu"
          style="flex-direction: row;padding:10px 5px;"
          v-model="landing.menu.list"
          draggable=".item"
      >
        <li
            class="canvas-menu__item canvas-menu-custom"
            :class="`${item.active?'item':'d-none'}`"
            v-for="item in landing.menu.list"
            :key="item.id"
            :style="
                  `margin-bottom:${+landing.menu.shadowSize + 10}px;` +
                  customMenuStyles(landing.menu, item)
                "
        >
          <div
              v-if="item.styles.show_name === 'top'"
              :style="`margin-bottom:10px;height:${
                    item.styles.lining_height
                  }px;width:${item.styles.lining_width}%;border-radius: ${
                    landing.menu.radius
                  }px;padding:${
                    item.styles.lining_padding
                  }px;background-color:${
                    item.styles.main_menu_lining
                  };display:flex;align-items:center;justify-content:center;flex-direction:${
                    item.styles.show_icon_up ? 'column' : 'row'
                  };`"
          >
            <img
                alt="image"
                v-if="
                      item.styles.show_icon && item.icon && item.styles.disable_icon_color
                    "
                @error="handleImgError"
                :src="item.icon[landing.current_lang]"
                class="canvas-menu__icon"
                :style="`height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    }`"
            />
            <div
                v-if="
                      item.styles.show_icon && item.icon && !item.styles.disable_icon_color
                    "
                @error="handleImgError"
                class="canvas-menu__icon"
                :style="`margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    };height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;background-color: ${item.styles.icon_color};mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;-webkit-mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;`"
            ></div>

            <div style="text-align: center">
              {{ item.text[landing.current_lang] }}
            </div>
          </div>
          <a
              class="canvas-menu__link"
              href="#"
              :style="
                    ` height:${item.styles.height}px;color: ${item.styles.color};
                    border-radius: ${landing.menu.radius}px;box-shadow:0 ${landing.menu.shadowSize}px 6px ${landing.menu.shadowColor};` +
                    customMenuLinkStyles(landing.menu, item)
                  "
          >
            <div
                v-if="item.styles.show_name === 'inside'"
                :style="`height:${item.styles.lining_height}px;width:${
                      item.styles.lining_width
                    }%;padding:${
                      item.styles.lining_padding
                    }px;background-color:${
                      item.styles.main_menu_lining
                    };display:flex;align-items:center;justify-content:center;flex-direction:${
                      item.styles.show_icon_up ? 'column' : 'row'
                    };`+checkRadius(item,landing.menu)"
            >
              <img
                  alt="image"
                  v-if="
                        item.styles.show_icon && item.icon && item.styles.disable_icon_color
                      "
                  @error="handleImgError"
                  :src="item.icon[landing.current_lang]"
                  class="canvas-menu__icon"
                  :style="`height:${item.styles.icon_size}px;width:${
                        item.styles.icon_size
                      }px;margin-right:${
                        !item.styles.show_icon_up ? '15px' : '0'
                      }`"
              />
              <div
                  v-if="
                        item.styles.show_icon && item.icon && !item.styles.disable_icon_color
                      "
                  @error="handleImgError"
                  class="canvas-menu__icon"
                  :style="`margin-right:${
                        !item.styles.show_icon_up ? '15px' : '0'
                      };height:${item.styles.icon_size}px;width:${
                        item.styles.icon_size
                      }px;background-color: ${
                        item.styles.icon_color
                      };mask: url(${
                        item.icon[landing.current_lang]
                      }) no-repeat center / contain;-webkit-mask: url(${
                        item.icon[landing.current_lang]
                      }) no-repeat center / contain;`"
              ></div>

              <div style="text-align: center">
                {{ item.text[landing.current_lang] }}
              </div>
            </div>
          </a>
          <div
              v-if="item.styles.show_name === 'bottom'"
              :style="`margin-top:10px;height:${
                    item.styles.lining_height
                  }px;width:${
                    item.styles.lining_width
                  }%;margin-top:10px;border-radius: ${
                    landing.menu.radius
                  }px;padding:${
                    item.styles.lining_padding
                  }px;background-color:${
                    item.styles.main_menu_lining
                  };display:flex;align-items:center;justify-content:center;flex-direction:${
                    item.styles.show_icon_up ? 'column' : 'row'
                  };`"
          >
            <img
                alt="image"
                v-if="
                      item.styles.show_icon && item.icon && item.styles.disable_icon_color
                    "
                @error="handleImgError"
                :src="item.icon[landing.current_lang]"
                class="canvas-menu__icon"
                :style="`height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    }`"
            />
            <div
                v-if="
                      item.styles.show_icon && item.icon && !item.styles.disable_icon_color
                    "
                @error="handleImgError"
                class="canvas-menu__icon"
                :style="`margin-right:${
                      !item.styles.show_icon_up ? '15px' : '0'
                    };height:${item.styles.icon_size}px;width:${
                      item.styles.icon_size
                    }px;background-color: ${item.styles.icon_color};mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;-webkit-mask: url(${
                      item.icon[landing.current_lang]
                    }) no-repeat center / contain;`"
            ></div>

            <div style="text-align: center">
              {{ item.text[landing.current_lang] }}
            </div>
          </div>
        </li>
    </draggable>
    <div v-if="advanced.tab === 3" style="text-align: center;margin-top:20px;">
      <h5>Your browser tab will looks like:</h5>
      <div
        style="display:flex;align-items:center;width: 100%;height:40px;border-top-left-radius: 5px;border-top-right-radius: 5px;background: #eee;"
      >
        <img
          style="height: 24px;width: 24px;margin: 0 10px;"
          :src="landing.advanced.global.favicon_url"
          alt="favicon"
        />
        <div>{{ title }}</div>
      </div>
    </div>
    <div v-if="advanced.tab === 4" style="text-align: center;margin-top:20px;">
      <h5>Your browser address bar will looks like:</h5>
      <div
        style="display:flex;align-items:center;width: 100%;padding:0 10px;height:40px;border-radius: 5px;background: #eee;"
      >
        <div>
          <v-icon color="#999" style="margin-right: 5px;">mdi-lock</v-icon
          >https://{{ landing.domain }}
        </div>
      </div>
    </div>
    <div v-if="advanced.tab === 9">
      <div
          v-if="page_404"
        style="padding: 20px 0 0 0;"
        v-html="page_404[current_lang].content"
      ></div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import draggable from "vuedraggable"
export default {
  name: "PreviewAdvanced",
  props: ["page", "landing"],
  components: {draggable},
  computed: {
    ...mapState('advanced',['title']),
    ...mapState('advanced',['page_404']),
    ...mapGetters("landing", [
      "advancedSuccessPage",
      "advanced",
      "current_lang",
    ]),
  },
  methods: {
    checkRadius(item,menu){
      if(+item.styles.lining_width === 100){
        if(item.styles.position === 'flex-start flex-start' || item.styles.position === 'flex-end flex-start' || item.styles.position === 'center flex-start'){
          return `border-top-right-radius:${menu.radius}px;border-top-left-radius:${menu.radius}px;`
        }
        if(item.styles.position === 'flex-start flex-end' || item.styles.position === 'flex-end flex-end' || item.styles.position === 'center flex-end'){
          return `border-bottom-right-radius:${menu.radius}px;border-bottom-left-radius:${menu.radius}px;`
        }
      }else{
        return `border-radius:${menu.radius}px;`
      }
    },
    handleImgError() {
      this.$emit("error");
    },

    customMenuStyles(menu, item) {
        return `width:calc(${item.styles.width}% - 10px);height:${item.styles.height}px;margin-right: 10px;`;
    },
    customMenuLinkStyles(menu, item) {
      let styles = "";
      if (menu.style === "custom" && item.styles.show_icon_up) {
        styles += "flex-direction:column;";
      } else if (menu.style === "custom" && !item.styles.show_icon_up) {
        styles += "flex-direction:row;";
      }
      styles += `font-size:${item.styles.fontSize}px;
                  justify-content:${item.styles.position.split(" ")[0]};
                  align-items:${item.styles.position.split(" ")[1]};
                  background-image: url('${item.styles.background}');
                  font-weight:${item.styles.fontWeight};
                  font-style:${item.styles.fontStyle};
                  font-family:'${item.styles.fontFamily}',Inter,sans-serif;
                  background-repeat: no-repeat;
                  background-size: cover;
                  background-position:center;`;
      return styles;
    },
  },
};
</script>

<style lang="scss">
.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 30px;
}
.v-application ul,
.v-application ol {
  padding: 0;
}
.canvas-menu {
  margin: 0;
  padding: 10px 5px;
  list-style: none;
  display: flex;
}
.canvas-menu-drag {
  display: flex;
  flex-wrap: wrap;
}

.canvas-menu_tile {
  flex-wrap: wrap;
  .canvas-menu__item {
    width: 50%;
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  .canvas-menu__link {
    flex-direction: column;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu-custom {
  width:100%;
  & .canvas-menu__item {
    margin-bottom: 0;
    padding: 0 5px 10px;
  }
  & .canvas-menu__link {
    background-repeat: no-repeat;
    background-size: cover;
  }
  .canvas-menu__icon {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.canvas-menu__item {
  margin-bottom: 10px;
}

.canvas-menu__link {
  display: flex;
  align-items: center;
  text-align: center;
  height: 100%;
  text-decoration: none;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  color: inherit;
}

.canvas-menu__icon {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  &.v-icon {
    // margin-bottom: 10px;
    margin-right: 10px;
    font-size: 36px;
  }
}
</style>
